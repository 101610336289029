























































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Contatos extends Vue {
  openWhatsapp() {
    window.open('https://api.whatsapp.com/send?phone=5562999299705');
  }

  openFacebook() {
    window.open('https://facebook.com/2m-impermeabilizações-e-engenharia-333280247372942/');
  }

  openInstagram() {
    window.open('https://instagram.com/2mimpermeabilizacoes/');
  }
}
