




import { Component, Vue } from "vue-property-decorator";
import AEmpresa from '@/components/Pages/AEmpresa.vue';

@Component({
    components: {
        AEmpresa
    }
})
export default class Empresa extends Vue {}
