













import { Component, Vue } from "vue-property-decorator";
import Localizacao from "@/components/Contato/Localizacao.vue";
import Contatos from "@/components/Contato/Contatos.vue";

@Component({
    components: {
        Localizacao,
        Contatos,
    },
})
export default class Contato extends Vue {}
