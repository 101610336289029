





































































import { Component, Vue } from "vue-property-decorator";
import { Menu } from "@/models/menu";

@Component
export default class NavBar extends Vue {
  drawer = false;
  tab = null;
  items: Array<Menu> = [
    new Menu(1, "Home", "/", "mdi-home"),
    new Menu(2, "A Empresa", "/empresa", "mdi-home-analytics"),
    new Menu(3, "Serviços", "/servicos", "mdi-cogs"),
    new Menu(4, "Portfólio", "/portfolio", "mdi-camera"),
    new Menu(5, "Parceiros", "/parceiros", "mdi-handshake"),
    new Menu(6, "Contato", "/contato", "mdi-phone"),
  ];

  navTo(menu: Menu) {
    if (this.$route.path !== menu.route) this.$router.push(menu.route);
  }

  navToDrawer(menu: Menu) {
    if (this.$route.path !== menu.route) this.$router.push(menu.route);
    this.drawer = false;
  }
}
