





































































import { Component, Vue } from "vue-property-decorator";
import Slider from '@/components/Slider/Slider.vue';

@Component({
    components: {
        Slider
    }
})
export default class Portfolio extends Vue {}
