




import { Component, Vue } from "vue-property-decorator";
import Parceiro from "@/components/Parceiros/Parceiro.vue";

@Component({
  components: {
    Parceiro,
  },
})
export default class Parceiros extends Vue {}
