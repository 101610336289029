



























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CardSlider extends Vue {
  @Prop() pasta!: string;
  @Prop() qtdArquivos!: number;
  model = 0;
}
