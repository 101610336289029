






















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Parceiro extends Vue {
    @Prop() nome!: string;
}
