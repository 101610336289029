export class Menu {
    public id: number;
    public name: string;
    public route: string;
    public icon: string;

    constructor(id: number, name: string, route: string, icon: string) {
        this.id = id;
        this.name = name;
        this.route = route;
        this.icon = icon;
    }
}