import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Inicio from '../views/Inicio.vue'
import Services from '../views/Services.vue'
import Empresa from '../views/Empresa.vue'
import Portfolio from '../views/Portfolio.vue'
import Contato from '../views/Contato.vue'
import Parceiros from '../views/Parceiros.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Home,
    children: [
      { path: '', name: 'Inicio', component: Inicio },
      { path: '/servicos', name: 'Servicos', component: Services },
      { path: '/empresa', name: 'Empresa', component: Empresa },
      { path: '/portfolio', name: 'Portfólio', component: Portfolio },
      { path: '/contato', name: 'Contato', component: Contato },
      { path: '/parceiros', name: 'Parceiros', component: Parceiros },
    ]
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
