
















import { Component, Prop, Vue } from "vue-property-decorator";
import CardSlider from "./CardSlider.vue";

@Component({
  components: {
    CardSlider,
  },
})
export default class Slider extends Vue {
  @Prop() pasta!: string;
  @Prop() titulo!: string;
  @Prop() qtdArquivos!: number;
  dialog = false;
  arquivoPadrao = `${this.pasta}/(1).jpg`;

  openModal() {
    this.dialog = true;
  }
}
