




import { Component, Vue } from "vue-property-decorator";
import Home from '@/components/Pages/Home.vue';

@Component({
    components: {
        Home
    }
})
export default class Inicio extends Vue {}
