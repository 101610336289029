




import { Component, Vue } from "vue-property-decorator";
import Servicos from '@/components/Pages/Servicos.vue';

@Component({
    components: {
        Servicos
    }
})
export default class Services extends Vue {}
