










import { Component, Vue } from 'vue-property-decorator';
import NavBar from '@/components/Home/NavBar.vue';
import Footer from '@/components/Home/Footer.vue';

@Component({
  components: {
    NavBar,
    Footer,
  }
})
export default class Home extends Vue {}
